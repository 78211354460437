#game-page-wrapper {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    /* max-height: 834px; editable */
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 16px;
    /* border: 1px solid red; */
}

.game-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
