.gallery-container {
    max-width: 60rem;
    width: 80%;
    padding: 4rem 1rem;
    margin: 0 auto;
}

.swiper_container {
    /* height: 100px; */
    /* padding: 2rem 0; */
    position: relative;
}
  
.swiper-slide {
    /* width: 100%; */
    /* height: ; */
    position: relative;
}



@media (max-width: 500px) {
    .swiper_container {
      height: 47rem;
    }
    .swiper-slide {
      width: 28rem !important;
      height: 36rem !important;
    }
    .swiper-slide img {
      width: 28rem !important;
      height: 36rem !important;
    }
  }
  
  .swiper-slide img {
    width: 27rem;
    height: 32rem;
    border-radius: 2rem;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
    border: 1px solid gray;
    object-fit: cover;
  }
  
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  
  .swiper-slide-prev{
      transform: translate3d(0px, 0px, -472px) rotateX(0deg) rotateY(0deg) scale(1) !important;

  }

  .swiper-slide-next {
    transform: translate3d(0px, 0px, -472px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }