#splash-wrapper {
    /* border: 1px solid red; */
    width: 100%;
    /* padding: 20px; */
    padding-top: 20px;
    max-width: 1400px;
    /* margin: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-color: #F2F2E9;
    margin: 0px auto;
}

/* @media (max-width: 1050px) {
    #splash-wrapper {
        width: 100%;
    }
} */