#lobby-wrapper {
    max-width: 1400px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 auto;
    font-family: 'JetBrains Mono', monospace;
    padding: 20px;
}

@media (max-width: 1050px) {
    #lobby-wrapper {
        gap: 8px;
    }
}