#login-wrapper {
    /* border: 1px solid red; */
    /* width: 100vw; */
    width: 80%;
    max-width: 1400px;
    height: 100vh;
    /* max-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    padding: 20px;
    gap: 20px;
}
#login-wrapper .login-header {
    
}

@media (max-width: 1050px) {
    #login-wrapper {
        width: 100%;
    }
}

@media (max-width: 720px) {
    #login-wrapper {
        /* align-items: flex-start; */
        justify-content: flex-start;
        height: auto;
    }
}

@media (max-height: 614px) {
    #login-wrapper {
        /* align-items: flex-start; */
        justify-content: flex-start;
        height: auto;
    }
}