#footer-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    padding: 40px;
    border-top: 1px solid gray;
    color: #454545;
}

#social-links-list {
    display: flex;
    gap: 8px;
}
.social-link-item-container {
    padding: 8px;
    border: 1.5px solid #454545;
    border-radius: 999px;
}

.social-link-item-container:hover {
    cursor: pointer;
}
