#join-room-form {
    width: 100%;
    text-align: center;

}

#join-room-form h1 {
    font-size: 2em;
}

#join-room-form input {
    margin: 16px 0px;
    font-size: 1.5em;
    padding: 1em 4em;
}

#copy-button {
    padding: 8px 12px;
    border: 1px solid var(--black);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s all ease;
}

#copy-button:hover {
    background-color: var(--black);
    color: var(--taupe);
}

@media (max-width: 520px) {
    #join-room-form h1 {
        font-size: 1.5em;
    }
    #join-room-form input {
        font-size: 1.2em;
        padding: 1em 1em;
    }
}