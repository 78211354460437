.logo-img {
    width: 100%;
}

.background {
    width: 100vw;
    height: 100vh;
    background-color: var(--backgroundColor);
    padding: 36px;
    display: flex;
    flex-direction: column;
}

.button {
    width: 100%;
    height: 50px;
    border: 1px solid var(--black);
    border-radius: var(--roundedBorder);
    box-shadow: 0px 6px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 32px;
    font-weight: 400;
}

.auth-input {
    width: 100%;
    border: 1px solid var(--gray);
    padding: 12px;
    border-radius: 8px;
    background-color: var(--off-white);
    font-size: 20px;
    outline: none;
}

/* @media (max-width: 935px) {
    .button {
        width:430px;
        font-size: 36px;
    }
} */


#logout-btn {
    border: 1px solid var(--black);
    padding: 4px 12px;
    border-radius: var(--roundedBorder);
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s all ease;
}

#logout-btn:hover {
    background-color: var(--black);
    color: var(--taupe);
}


@media (max-width: 500px) {
    .auth-input {
        font-size: 16px;
    }
    .button {
        /* width:390px; */
        font-size: 32px;
    }
}