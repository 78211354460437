#join-room-wrapper {
    width: 80%;
    max-width: 1400px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    padding: 20px;
    gap: 20px;
}

#join-room-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.join-forms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

#join-room-wrapper .greeting {
    font-size: 40px;
    text-align: left;
}

#join-room-wrapper .current-user {
    color: orange
}

.form-input {
    width: 100%;
    border: 1px solid var(--gray);
    padding: 12px;
    border-radius: 8px;
    background-color: var(--off-white);
    font-size: 16px;
    outline: none;
    text-align: center;
}

@media (max-width: 1050px) {
    #join-room-wrapper {
        width: 100%;
    }
}

@media (max-width: 614px) {
    #join-room-wrapper {
        /* align-items: flex-start; */
        justify-content: flex-start;
        height: auto;
    }
}

@media (max-height: 614px) {
    #join-room-wrapper {
        /* align-items: flex-start; */
        justify-content: flex-start;
        height: auto;
    }
}