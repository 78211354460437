#creators-list {
    display: flex;
    gap: 20px;
    /* flex-wrap: wrap; */
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.react-card-flip {
    width: 100%;
}

.creators-list_list-item {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* align-items: center; */
    width: 100%;
    min-width: 150px;
    max-width: 300px;
    border: 2px solid black;
    border-radius: 24px;
    background-color: #F4F6DC;
    box-shadow: 3px 3px;
    position: relative;
    transition: 0.3s all ease;
    height: 100%;
    overflow: hidden;
    /* padding-bottom: 12px; */
}

.creators-list_list-item .back {
    width: auto;
    height: auto;
    overflow-y: auto;
}

.creators-list_list-item .creator-image {
    width: 100%;
    height: 100%;
    background: gray;
    overflow: hidden;
    margin-bottom: 8px;
}

.creators-list_list-item .creator-image img {
    width: 100%;
    /* min-width: 200px; */
    height: 100%;
    object-fit: cover;
    aspect-ratio : 1 / 1;
}

.creators-list_list-item .creator-name {
    font-size: 1.2em;
    font-weight: 500;
    margin-left: 12px;
    line-height: 0.8;
    margin-top: 12px;
}

.creators-list_list-item .creator-role {
    font-size: 0.875em;
    margin-left: 12px;
    font-weight: 300;
    line-height: 1;
    padding-top: 3px;
}

.creators-list_list-item .social-links {
    display: flex;
    gap: 8px;
    align-items: end;
    /* justify-content: center; */
    margin-left: 12px;
    margin-bottom: 12px;

}


@media (max-width: 1080px) {
    .creators-list_list-item .creator-summary {
        font-size: 0.875em;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px 4px;
        text-align: center;
        cursor: pointer;
        line-height: 1.2;
    }

    .creators-list_list-item .social-links {
        margin-top: 12px;
    }

    .creators-list_list-item .back {
        padding-bottom: 0px;
    }
}

@media (max-width: 715px) {
    #creators-list {
        /* flex-wrap: nowrap; */
    }

    
    .creators-list_list-item {
        /* width: 100%;
        min-width: 150px;
        max-width: 220px; */
    }
}

@media (max-width: 960px) {

    #creators-list {
        flex-wrap: wrap;
    }

    .react-card-flip {
        width: 48%;
    }
    .creators-list_list-item {
        /* width: 75%; */
        max-width: none;

    }

    .creators-list_list-item .creator-summary {
        font-size: 1em;
        padding: 0px 12px;
    }
}

@media (max-width: 585px) {
    #creators-list {
        width: 100%;
    }

    .react-card-flip {
        width: 100%;
        max-width: 354px;
    }


    .creators-list_list-item {
        /* width: 100%; */
        /* min-width: 250px; */
        /* max-width: none; */
    }
}