
.R24 {
    font-size: 24px;
    font-weight: 300
}

.R30 {
    font-size: 30px;
    font-weight: 300;
}
.R30-center {
    font-size: 30px;
    font-weight: 300;
    text-align: center;
}

.R36 {
    font-size: 36px;
    font-weight: 300;
}

.R36-center {
    font-size: 36px;
    font-weight: 300;
    text-align: center;
}

.R48 {
    font-size: 48px;
    font-weight: 300;
}

.R60 {
    font-size: 60px;
    font-weight: 300
}

.B36 {
    font-size: 36px;
    font-weight: 700
}

.B44 {
    font-size: 36px;
    font-weight: 700
}

.LH100 {
    line-height: 120%;
}
