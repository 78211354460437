/* TODO Add site wide styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

:root {
    --fontManrope: 'Manrope', sans-serif;
    --fontJetBrains: 'JetBrains Mono', monospace;
    --fontMonoMajorDisplay: 'Major Mono Display', monospace;
    --roundedBorder: 999px;
    --black: #0D160B;
    --gray: #ACACAC;
    --green: #5C8B28;
    --taupe: #F2F2E9;
    --off-white: #F9F5F5;

    --player-red: #F2A1A1;
    --player-pink: #E7B4F0;
    --player-purple: #C6C6E8;
    --player-blue: #B4CFF0;
    --player-green: #AFD2B5;
    --player-yellow: #F6DAA3;
}

html {
    box-sizing: border-box;
    background-color: var(--taupe)
    
}

*, *:before, *:after {
    box-sizing: inherit;
    box-sizing: border-box;
    /* border: 1px solid red; */
}


  

body {
    font-family: var(--fontJetBrains);
    background-color: var(--backgroundColor);
    color: var(--textColor);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;

    
}
.svg-img {
    width: 100%;
}