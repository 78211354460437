
#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 35;
  }
  
  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 40;
  }
  
  #modal-content {
    border-radius: 24px;
    position: absolute;
    max-width: 750px;
    width:60vw;
    min-height: 300px;
    max-height: 80vh;
    z-index: 1000;
    /* background-color: red; */

  }

@media (max-width: 1300px) {
  #modal-content {
    width: 80vw;
  }
}