.hamburger-menu-button {
    background-color: #D6D6CC;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s all ease;

}

.hamburger-menu-button:hover{
    background-color: #C2C2B6;
}

.hamburger-menu-side-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 260px;
    background-color: #f3efef;
    z-index: 100;
    padding: 20px;
    padding-top: 40px;
    transition: 1s all ease;
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    color: rgb(60, 60, 60);
    /* pointer-events: none; */
}

.hamburger-menu-side-nav_nav-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    margin-right: 12px;
    border-radius: 12px;
    cursor: pointer;
}

.hamburger-menu-side-nav_nav-item .active {
    background-color: red;
}

.hamburger-menu-side-nav_nav-item:hover {
    background-color: rgb(203, 198, 198);
}

.open-menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: calc(100% - 260px);
    height: 100%;
    /* background-color: red; */
    backdrop-filter: blur(3px);
}

.nav-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
    --animate-duration: 0.3s;
}


